import { Component, Input, OnInit } from '@angular/core';
import { LoadingController, ModalController, ToastController } from '@ionic/angular';
import { Property } from '@core/models/interfaces/property.interface';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { getThumbnailUrl } from '@core/models/utils/media.utils';
import { PropertyService } from '@core/api/property/property.service';
import { getTranslationForPropertyType } from '@app/@core/models/utils/type.translation';
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';
import { dataURLtoBlob } from '@core/utils/data-to-blob';
import { Capacitor } from '@capacitor/core';
import { TranslateService } from '@ngx-translate/core';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-property-mask',
  templateUrl: './property-mask.component.html',
  styleUrls: ['./property-mask.component.scss'],
})
export class PropertyMaskComponent implements OnInit {
  public static MODAL_ID = 'property-mask-component';

  @Input() property?: Property;

  private propertyForm: UntypedFormGroup;
  private isFileLoading = new BehaviorSubject(false);
  private propertyTypesFromContent: string[] = [];
  private filename: string;

  constructor(private readonly modalController: ModalController,
              private readonly propertyService: PropertyService,
              private readonly toastController: ToastController,
              private readonly formBuilder: UntypedFormBuilder,
              private readonly loading: LoadingController,
              private translateService :TranslateService,
              ) {
    this.propertyService.getPropertyTypes().then((values) => this.propertyTypesFromContent = values);
  }

  public get propertyPhoto() {
    return getThumbnailUrl(this.property?.photo);
  }

  public get form(): UntypedFormGroup {
    return this.propertyForm;
  }

  public get propertyTypes() {
    return this.propertyTypesFromContent;
  }

  public getTranslationForType(type: string) {
    return getTranslationForPropertyType(type, this.translateService);
  }

  public ngOnInit(): void {
    this.propertyForm = this.createForm();
  }

  public async dismiss(): Promise<void> {
    await this.modalController.dismiss({
      dismissed: true,
    }, undefined, PropertyMaskComponent.MODAL_ID);
  }

  public async submit(event: any): Promise<void> {
    event.preventDefault();
    event.stopPropagation();
    if (this.form.invalid || this.form.disabled) {
      return;
    }

    this.form.disable();
    if (this.isFileLoading.value === true) {
      const loading = await this.loading.create();
      await loading.present();
      const sub = this.isFileLoading.subscribe(() => {
        sub.unsubscribe();
        loading.dismiss();
        this.submit(event);
      });
      return;
    }

    const {
      name, description, street, streetNumber, zip, city, photo, photoFile, type,
    } = this.form.value;

    const property = {
      ...this.property,
      name,
      description,
      type,
      address: {
        street,
        streetNumber,
        zip,
        city,
        country: this.property?.address?.country ?? 'Germany',
      },
    } as Property;
    delete property.units;
    delete property.photo;

    let result: any = {
      property,
    };

    if (photo !== this.propertyPhoto) {
      result = { ...result, photo: photoFile, filename: this.filename };
    }

    await this.modalController.dismiss(result, undefined, PropertyMaskComponent.MODAL_ID);
    this.form?.enable();
  }

  async takePhoto() {
    const translations = await firstValueFrom(this.translateService.get([
      'components.property-mask.okay-action',
      'components.property-mask.take-photo-camera-denied-header',
      'components.property-mask.take-photo-camera-denied-message'
    ]));
    if (Capacitor.isNativePlatform()) {
      const permissions = await Camera.checkPermissions();
      if (permissions.camera === 'denied') {
        await (await this.toastController.create({
          header: translations['components.property-mask.take-photo-camera-denied-header'],
          message: translations['components.property-mask.take-photo-camera-denied-message'],
          buttons: [translations['components.property-mask.okay-action']],
          color: 'danger',
          duration: 5000,
          position: 'top',
        })).present();

        return;
      }
      if (permissions.camera === 'prompt' || permissions.camera === 'prompt-with-rationale') {
        await Camera.requestPermissions({ permissions: ['camera'] });
        return await this.takePhoto();
      }
    }

    const image = await Camera.getPhoto({
      source: CameraSource.Camera,
      quality: 90,
      allowEditing: true,
      resultType: CameraResultType.DataUrl,
    });
    const dataUrl = image.dataUrl;
    this.filename = 'photo' + new Date().toISOString()
        .replace(/:/g, '-')
        .replace(/\./g, '_')
      + '.' + image.format;
    this.form.patchValue({
      photo: dataUrl,
      photoFile: dataURLtoBlob(dataUrl),
    });
  }

  async chooseFromGallery() {
    const translations = await firstValueFrom(this.translateService.get([
      'components.property-mask.okay-action',
      'components.property-mask.choose-from-gallery-denied-header',
      'components.property-mask.choose-from-gallery-denied-message'
    ]));
    if (Capacitor.isNativePlatform()) {
      const permissions = await Camera.checkPermissions();
      if (permissions.photos === 'denied') {
        await (await this.toastController.create({
          header: translations['components.property-mask.choose-from-gallery-denied-header'],
          message: translations['components.property-mask.choose-from-gallery-denied-message'],
          buttons: [translations['components.property-mask.okay-action']],
          color: 'danger',
          duration: 5000,
          position: 'top',
        })).present();

        return;
      }
      if (permissions.photos === 'prompt' || permissions.photos === 'prompt-with-rationale') {
        await Camera.requestPermissions({ permissions: ['photos'] });
        return await this.chooseFromGallery();
      }
    }

    const image = await Camera.getPhoto({
      source: CameraSource.Photos,
      quality: 90,
      allowEditing: true,
      resultType: CameraResultType.DataUrl,
    });
    const dataUrl = image.dataUrl;
    this.filename = 'photo' + new Date().toISOString()
        .replace(/:/g, '-')
        .replace(/\./g, '_')
      + '.' + image.format;
    this.form.patchValue({
      photo: dataUrl,
      photoFile: dataURLtoBlob(dataUrl),
    });
  }

  private createForm(): UntypedFormGroup {
    return this.formBuilder.group({
      name: [this.property?.name ?? '', Validators.compose([Validators.required, Validators.minLength(3)])],
      description: [this.property?.description ?? '', Validators.compose([Validators.minLength(3)])],
      type: [this.property?.type ?? '', Validators.compose([Validators.required])],
      street: [this.property?.address.street ?? '', Validators.compose([Validators.required])],
      streetNumber: [this.property?.address.streetNumber ?? '', Validators.compose([Validators.required])],
      zip: [this.property?.address.zip ?? '', Validators.compose([Validators.required])],
      city: [this.property?.address.city ?? '', Validators.compose([Validators.required])],
      photo: [this.propertyPhoto ?? ''],
      photoFile: [null],
    });
  }
}

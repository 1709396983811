import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Problem } from '@core/models/interfaces/problem.interface';
import { Pagination } from '@core/models/datatypes/pagination.interface';
import { User } from '@core/models/interfaces/user.interface';
import { ProblemService } from '@core/api/problem-service/problem.service';
import { UserService } from '@core/api/user/user.service';
import { Logger } from '@core/logger/logger.service';
import { UsesCacheComponent } from '@core/api/cache/uses-cache';
import { CacheService } from '@app/@core/api/cache/cache.service';
import { TranslateService } from '@ngx-translate/core';

const logger = new Logger('a.@s.c.p.ProblemChooserMaskComponent');

@Component({
  selector: 'app-problem-chooser-mask',
  templateUrl: './problem-chooser-mask.component.html',
  styleUrls: ['./problem-chooser-mask.component.scss'],
})
export class ProblemChooserMaskComponent extends UsesCacheComponent implements OnInit {
  public static readonly MODAL_ID = 'problem-chooser-mask-component';
  public displayedColumns = ['id', 'body', 'status', 'date', 'reporter', 'property', 'unit'];
  private internalProblems?: Problem[];
  private internalPagination?: Pagination;
  private currentPage = 1;
  private pageSize = 10;
  private sortField = 'createdAt';
  private sortMode = 'desc';
  private isLoading = false;
  private internalUser: User;

  constructor(
    private readonly modalController: ModalController,
    private readonly problemService: ProblemService,
    private readonly userService: UserService,
    protected readonly cacheService: CacheService,
    private translateService :TranslateService,
  ) {
    super(cacheService);
  }

  get role() {
    return this.internalUser?.role?.type ?? 'public';
  }

  get user() {
    return this.internalUser;
  }

  get pagination() {
    return this.internalPagination;
  }

  get problems() {
    return this.internalProblems;
  }

  get loading() {
    return this.isLoading;
  }

  async onCacheCleared(lastRefresh: Date): Promise<void> {
    this.internalUser = await this.userService.getMe();
    await this.loadProblems();
  }

  async ngOnInit() {
    this.internalUser = await this.userService.getMe();
    await this.loadProblems();
    this.isInitialized = true;
  }

  public trackById(_, entity: { id: number }) {
    return entity?.id ?? _;
  }

  statusColor(problem: Problem) {
    switch (problem.status ?? 'open') {
      case 'open':
        return 'danger';
      case 'in_progress':
        return 'tertiary';
      case 'closed':
        return 'success';
      default:
        return 'medium';
    }
  }

  status(problem: Problem) {
    switch (problem.status ?? 'open') {
      case 'open':
        return 'components.problem-chooser-mask.open';
      case 'in_progress':
        return 'components.problem-chooser-mask.in-Progress';
      case 'closed':
        return 'components.problem-chooser-mask.completed';
      default:
        return 'components.problem-chooser-mask.Unknown';
    }
  }

  async goToNextPage() {
    if (!this.pagination) {
      return;
    }

    if (this.pagination.page === this.pagination.pageCount) {
      return;
    }
    this.currentPage++;
    await this.loadProblems();
  }

  async goToPreviousPage() {
    if (!this.pagination) {
      return;
    }
    if (this.pagination.page === 1) {
      return;
    }
    this.currentPage--;
    await this.loadProblems();
  }

  async changeAmountOfItems($event: number) {
    if (!this.pagination) {
      return;
    }
    if ($event === this.pagination.pageSize) {
      return;
    }

    this.currentPage = 1;
    this.pageSize = $event;
    await this.loadProblems();
  }

  async goToLastPage() {
    if (!this.pagination) {
      return;
    }
    if (this.pagination.page === this.pagination.pageCount) {
      return;
    }

    this.currentPage = this.pagination.pageCount;
    await this.loadProblems();
  }

  async goToFirstPage() {
    if (!this.pagination) {
      return;
    }
    if (this.pagination.page === 1) {
      return;
    }
    this.currentPage = 1;
    await this.loadProblems();
  }

  getIcon(field: string) {
    if (this.sortField !== field) {
      return 'swap-vertical-outline';
    }
    if (this.sortMode === 'asc') {
      return 'arrow-up';
    }

    return 'arrow-down';
  }

  async changeSort(field: string) {
    if (this.sortField !== field) {
      this.sortField = field;
      this.sortMode = 'asc';
    } else {
      this.sortMode = this.sortMode === 'asc' ? 'desc' : 'asc';
    }
    this.currentPage = 1;
    await this.loadProblems();
  }

  async dismiss() {
    await this.modalController.dismiss(undefined, 'cancel', ProblemChooserMaskComponent.MODAL_ID);
  }

  public hasPermissions() {
    return ['manager', 'admin'].includes(this.role);
  }

  async chooseProblem(problem: Problem) {
    await this.modalController.dismiss({
      problem,
    }, 'select', ProblemChooserMaskComponent.MODAL_ID);
  }

  private async loadProblems() {
    this.isLoading = true;
    try {
      const {
        data,
        meta,
      } = await this.problemService.getProblemsWithStatus(
        this.hasPermissions(),
        this.sortField,
        this.sortMode,
        this.currentPage,
        this.pageSize,
      );
      this.internalProblems = data;
      this.internalPagination = meta;
    } catch (e) {
      logger.error(e);
    }
    this.isLoading = false;
  }

  getTranslated(key: string){
    return this.translateService.get(key);
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { Property } from '@app/@core/models/interfaces/property.interface';
import { AlertController, ModalController, NavController, ToastController } from '@ionic/angular';
import { Logger } from '@core/logger/logger.service';
import { PropertyService } from '@app/@core/api/property/property.service';
import { Image } from '@core/models/datatypes/image.interface';
import { getImageUrl } from '@core/models/utils/media.utils';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { InvitationService } from '@app/@core/api/invitation/invitation.service';
import { UserService } from '@core/api/user/user.service';
import { User } from '@core/models/interfaces/user.interface';
import { UsesCacheComponent } from '@core/api/cache/uses-cache';
import { CacheService } from '@app/@core/api/cache/cache.service';
import { TranslateService } from '@ngx-translate/core';
import { firstValueFrom } from 'rxjs';

const logger = new Logger('a.@s.c.m.ManagerMaskComponent');

@Component({
  selector: 'app-manager-mask',
  templateUrl: './manager-mask.component.html',
  styleUrls: ['./manager-mask.component.scss'],
})
export class ManagerMaskComponent extends UsesCacheComponent implements OnInit {
  public static MODAL_ID = 'manager-mask-component';
  @Input() property: Property;
  public addManagerFormGroup: UntypedFormGroup;
  public accordionOpen = 'managers';
  private hasChangedData = false;

  constructor(
    private readonly modalController: ModalController,
    private readonly navigation: NavController,
    private readonly toastController: ToastController,
    private readonly alertController: AlertController,
    private readonly propertyService: PropertyService,
    private readonly userService: UserService,
    private readonly invitationService: InvitationService,
    private readonly formBuilder: UntypedFormBuilder,
    protected readonly cacheService: CacheService,
    private translateService :TranslateService,
  ) {
    super(cacheService);
    this.addManagerFormGroup = this.createForm();
  }

  async onCacheCleared(lastRefresh: Date): Promise<void> {
    if (this.property.managers == null) {
      this.property = await this.propertyService.getPropertyById(this.property.id);
    }
  }

  public trackById(_, entity: { id: number }) {
    return entity.id;
  }

  async ngOnInit() {
    if (this.property.managers == null) {
      logger.debug('Loading managers of ' + this.property.id);
      this.property = await this.propertyService.getPropertyById(this.property.id);
      logger.debug(this.property);
    }
    this.isInitialized = true;
  }

  public async dismiss(): Promise<void> {
    await this.modalController.dismiss(
      {
        dismissed: true,
        hasChangedData: this.hasChangedData,
      },
      undefined,
      ManagerMaskComponent.MODAL_ID,
    );
  }

  async openInUsers(manager: User) {
    await this.modalController.dismiss(
      {
        dismissed: true,
        hasChangedData: this.hasChangedData,
        navigate: ['/', 'app', 'users', manager.id],
      },
      undefined,
      ManagerMaskComponent.MODAL_ID,
    );
  }

  openManagerTab() {
    this.accordionOpen = 'addManager';
  }

  getProfilePicture(profilePicture?: Image) {
    if (profilePicture) {
      return getImageUrl(profilePicture);
    }
    return '/assets/images/avatar.svg';
  }

  cancelAdd() {
    this.addManagerFormGroup.reset();
    this.accordionOpen = 'managers';
  }

  async addNewManager() {
    if (this.addManagerFormGroup.invalid || this.addManagerFormGroup.disabled) {
      return;
    }
    this.addManagerFormGroup.disable();

    const { firstname, lastname, email } = this.addManagerFormGroup.value;
    const translations = await firstValueFrom(this.translateService.get([
      'components.manager-mask.okay-action',
      'components.manager-mask.error-header',
      'components.manager-mask.add-manager-error-message',
      'components.manager-mask.add-manager-error',
      'components.manager-mask.add-manager-header',
      'components.manager-mask.add-manager-invitation-header',
      'components.manager-mask.add-manager-invitation-message'
    ]));

    try {
      const existingUser = await this.userService.findManagerByEmail(email);
      logger.debug(existingUser);
      if (existingUser) {
        await this.propertyService.addManager(this.property, existingUser.id);

        const toast = await this.toastController.create({
          header: translations['components.manager-mask.add-manager-header'],
          message: this.translateService.instant('components.manager-mask.add-manager-message', {firstname: existingUser.firstname, lastname: existingUser.lastname}),
          color: 'success',
          duration: 3000,
          buttons: [translations['components.manager-mask.okay-action']],
        });
        this.hasChangedData = true;
        await toast.present();
        await this.clearCacheAndWait();
        this.property = await this.propertyService.getPropertyById(this.property.id);
        this.accordionOpen = 'managers';
      } else {
        const answer = await this.invitationService.inviteManager(firstname, lastname, email);
        if (answer != null) {
          if (!answer.userInvitable) {
            this.addManagerFormGroup.reset();
            const toast = await this.toastController.create({
              header: translations['components.manager-mask.error-header'],
              message: translations['components.manager-mask.add-manager-error-message'],
              color: 'danger',
              duration: 5000,
              buttons: [translations['components.manager-mask.okay-action']],
            });
            await toast.present();
          } else {
            this.addManagerFormGroup.reset();

            const newUser = await this.userService.findManagerByEmail(email);
            if (!newUser) {
              throw new Error(this.translateService.instant('components.manager-mask.find-user-as-manager-error'));
            }
            await this.propertyService.addManager(this.property, newUser.id);
            this.hasChangedData = true;

            const toast = await this.toastController.create({
              header: translations['components.manager-mask.add-manager-invitation-header'],
              message: translations['components.manager-mask.add-manager-invitation-message'],
              color: 'success',
              duration: 5000,
              buttons: [translations['components.manager-mask.okay-action']],
            });
            await toast.present();
            await this.clearCacheAndWait();
            this.property = await this.propertyService.getPropertyById(this.property.id);
            this.accordionOpen = 'managers';
          }
        } else {
          throw new Error(this.translateService.instant('components.manager-mask.add-manager-invitation-error'));
        }
      }
      this.addManagerFormGroup.reset();
    } catch (e) {
      logger.error(e);
      const toast = await this.toastController.create({
        header: translations['components.manager-mask.error-header'],
        message: this.translateService.instant('components.manager-mask.add-manager-error', {error: e}),
        color: 'danger',
        duration: 5000,
        buttons: [translations['components.manager-mask.okay-action']],
      });
      await toast.present();
    } finally {
      this.addManagerFormGroup?.enable();
    }
  }

  async removeManager(manager: User) {
    const translations = await firstValueFrom(this.translateService.get([
      'components.manager-mask.okay-action',
      'components.manager-mask.no-action',
      'components.manager-mask.yes-action',
      'components.manager-mask.remove-manager-alert-header',
      'components.manager-mask.remove-manager-header'
    ]));

    const request = await this.alertController.create({
      header: translations['components.manager-mask.remove-manager-alert-header'],
      message: this.translateService.instant('components.manager-mask.remove-manager-alert-message', {firstname: manager.firstname, lastname: manager.lastname}),
      buttons: [{ text: translations['components.manager-mask.no-action'], role: 'cancel' }, { text: translations['components.manager-mask.yes-action'], role: 'destructive' }],
    });

    await request.present();
    const answer = await request.onDidDismiss();
    if (answer.role !== 'destructive') {
      return;
    }

    try {
      await this.propertyService.removeManager(this.property, manager.id);
      const toast = await this.toastController.create({
        header: translations['components.manager-mask.remove-manager-header'],
        message: this.translateService.instant('components.manager-mask.remove-manager-message', {firstname: manager.firstname, lastname: manager.lastname, name: this.property.name}),
        color: 'warning',
        duration: 5000,
        buttons: [translations['components.manager-mask.okay-action']],
      });
      await toast.present();
      await this.clearCacheAndWait();
      this.property = await this.propertyService.getPropertyById(this.property.id);
      this.accordionOpen = 'managers';
    } catch (e) {
      logger.error(e);
      const toast = await this.toastController.create({
        header: translations['components.manager-mask.error-header'],
        message: this.translateService.instant('components.manager-mask.add-manager-error', {error: e}),
        color: 'danger',
        duration: 5000,
        buttons: [translations['components.manager-mask.okay-action']],
      });
      await toast.present();
    }
  }

  private createForm(): UntypedFormGroup {
    return this.formBuilder.group({
      firstname: ['', Validators.required],
      lastname: ['', Validators.required],
      email: ['', Validators.compose([Validators.required, Validators.email])],
    });
  }
}

import { Component, computed, effect, inject, input, model, output, signal } from '@angular/core';
import { Document, DocumentFolder } from '@core/models/interfaces/document.interface';
import { Pagination } from '@core/models/datatypes/pagination.interface';
import { DocumentService } from '@core/api/document/document.service';
import { DocumentFolderService } from '@core/api/document/document-folder.service';
import { apiSettings } from '@env/environment';
import { firstValueFrom } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { Logger } from '@core/logger/logger.service';
import { AlertController, IonInput, IonRouterOutlet, LoadingController, ModalController, ToastController } from '@ionic/angular';
import { MediaService } from '@core/api/media/media.service';
import { UsesCacheComponent } from '@core/api/cache/uses-cache';
import { CacheService } from '@core/api/cache/cache.service';
import { Capacitor } from '@capacitor/core';
import { MediaCarouselModalComponent } from '@shared/components/media-carousel-modal/media-carousel-modal.component';
import { PreviewAnyFile } from '@awesome-cordova-plugins/preview-any-file/ngx';
import { memoize } from '@shared/directives/memoize';
import { UserAssignMaskComponent } from '@shared/components/user-assign-mask/user-assign-mask.component';
import { BulkUserAssignMaskComponent } from '@shared/components/bulk-user-assign-mask/bulk-user-assign-mask.component';
import { DocumentMaskComponent } from '@shared/components/document-mask/document-mask.component';

const logger = new Logger('a.@s.c.d.DocumentListComponent');

@Component({
  selector: 'app-document-list',
  templateUrl: './document-list.component.html',
  styleUrls: ['./document-list.component.scss'],
})
export class DocumentListComponent extends UsesCacheComponent {
  public isIntegrated = input<boolean>(false);
  public selectedProperty = input<number | undefined>();
  public selectedUnit = input<number | undefined>();
  public hasPermissions = input<boolean>();
  public searchTerm = input<string | undefined>();
  public isFuzzySearch = input<boolean>(false);
  public isTemplate = input<boolean>(false);
  public selectedFolder = output<DocumentFolder | undefined>();
  public isCreatingFolder = signal(false);
  editingFolder = signal<number | undefined>(undefined);
  editingFile = signal<number | undefined>(undefined);
  editingName = model<string>();
  protected foldersPagination = signal<Pagination | undefined>(undefined);
  protected documentsPagination = signal<Pagination | undefined>(undefined);
  protected documents = signal<Document[]>([]);
  protected folders = signal<DocumentFolder[]>([]);
  protected selectedFolderSignal = signal<DocumentFolder | undefined>(undefined);
  protected path = computed(() => {
    const folder = this.selectedFolderSignal();
    if (!folder) {
      return '/';
    }
    if (folder.parent != null) {
      return '/../' + folder.parent.name + '/' + folder.name;
    }
    return '/' + folder.name;
  });
  protected checkedFolders = signal<number[]>([]);
  protected checkedFoldersSet = computed(() => new Set(this.checkedFolders()));
  protected checkedDocuments = signal<number[]>([]);
  filesOrFolderSelected = computed(() => this.checkedDocuments().length + this.checkedFolders().length);
  protected checkedDocumentsSet = computed(() => new Set(this.checkedDocuments()));
  hasSomeChecked = computed(() => {
    const folders = this.folders();
    const documents = this.documents();
    const checkedDocuments = this.checkedDocumentsSet();
    const checkedFolders = this.checkedFoldersSet();

    return folders.some(f => checkedFolders.has(f.id)) || documents.some(f => checkedDocuments.has(f.id));
  });
  hasAllChecked = computed(() => {
    const folders = this.folders();
    const documents = this.documents();
    const checkedDocuments = this.checkedDocumentsSet();
    const checkedFolders = this.checkedFoldersSet();

    const folderIdSet = new Set(folders.map(f => f.id));
    const documentIdSet = new Set(documents.map(f => f.id));

    for (const entry of folderIdSet) {
      if (!checkedFolders.has(entry)) {
        return false;
      }
    }

    for (const entry of documentIdSet) {
      if (!checkedDocuments.has(entry)) {
        return false;
      }
    }

    return true;
  });
  protected currentFolderPage = signal(1);
  protected currentDocumentPage = signal(1);
  protected folderPageSize = signal(25);
  protected documentPageSize = signal(25);
  private documentService = inject(DocumentService);
  private documentFolderService = inject(DocumentFolderService);
  private translateService = inject(TranslateService);
  private alertController = inject(AlertController);
  private loadingController = inject(LoadingController);
  private imageService = inject(MediaService);
  private toastController = inject(ToastController);
  private readonly previewAnyFile = inject(PreviewAnyFile);
  private modalController = inject(ModalController);
  private readonly routerOutlet = inject(IonRouterOutlet);
  private sortField = 'title';
  private sortMode = 'asc';

  constructor(protected cacheService: CacheService) {
    super(cacheService);
    effect(() => {
      const signal = this.selectedFolderSignal();
      this.selectedFolder.emit(signal);
    });
    effect(() => {
      const property = this.selectedProperty();
      const unit = this.selectedUnit();
      const folder = this.selectedFolderSignal();
      const page = this.currentFolderPage();
      const size = this.folderPageSize();

      this.loadFolders(folder?.id, property, unit, page, size).then();
    });
    effect(() => {
      const property = this.selectedProperty();
      const unit = this.selectedUnit();
      const folder = this.selectedFolderSignal();
      const page = this.currentDocumentPage();
      const size = this.documentPageSize();
      this.loadDocuments(false, folder?.id, property, unit, page, size).then();
    });
    effect(() => {
      const property = this.selectedProperty();
      const unit = this.selectedUnit();
      const folder = this.selectedFolderSignal();

      if (folder && (property !== undefined || unit !== undefined)) {
        if (folder.property?.id !== property || folder.unit?.id !== unit) {
          this.selectedFolderSignal.set(undefined);
        }
      }
    }, { allowSignalWrites: true });
  }

  async onCacheCleared(lastRefresh: Date): Promise<void> {
    await this.triggerLoadFolders();
    await this.triggerLoadDocuments();
  }

  public async triggerLoadDocuments() {
    const page = this.currentDocumentPage();
    const size = this.documentPageSize();
    await this.loadDocuments(false, this.selectedFolderSignal()?.id, this.selectedProperty(), this.selectedUnit(), page, size);
  }

  public async triggerLoadFolders() {
    await this.loadFolders(this.selectedFolderSignal()?.id, this.selectedProperty(), this.selectedUnit(), this.currentFolderPage(), this.folderPageSize());
  }

  async download(toBeDownloaded: Document) {
    if (!toBeDownloaded.file) {
      return;
    }
    const url = apiSettings.baseUrl + toBeDownloaded.file.url;
    const filename = toBeDownloaded.file.name;

    // if (Capacitor.isNativePlatform()) {
    //   const l = await Http.addListener('progress', (progress) => {
    //     this.downloaded = progress.bytes / progress.contentLength;
    //     this.changeDetector.detectChanges();
    //   });
    //   try {
    //     this.downloading = true;
    //     const options = await getFileDownloadOptions(url, filename);
    //     const result = await Http.downloadFile(options);
    //
    //     const path = result.path.replace('\\', '/');
    //     const slice = path.split('/').slice(-2);
    //     const realPath = (Capacitor.getPlatform() === 'ios' ? 'IRES/' : '') + slice.join('/');
    //
    //     const toast = await this.toastController.create({
    //       message: `Heruntergeladen nach: ${realPath}`,
    //       color: 'success',
    //       duration: 3000,
    //       buttons: ['Okay'],
    //     });
    //     await toast.present();
    //   } catch (e) {
    //     logger.error(e);
    //     const toast = await this.toastController.create({
    //       header: 'Fehler!',
    //       message: 'Es gab einen Fehler: ' + e?.details?.message ?? e,
    //       duration: 5000,
    //       buttons: ['ok'],
    //       color: 'danger',
    //       position: 'top',
    //     });
    //     await toast.present();
    //   } finally {
    //     this.downloading = false;
    //     this.downloaded = 0;
    //     await l.remove();
    //   }
    // } else {
    const element = document.createElement('a');
    element.setAttribute('href', url);
    element.setAttribute('download', 'download');
    element.setAttribute('target', '_blank');
    document.body.appendChild(element);
    element.click();
    //}
  }

  async preview($event: Document) {
    const translations = await firstValueFrom(this.translateService.get(['components.document-list.okay-action', 'components.document-list.preview-document-error']));
    if (Capacitor.isNativePlatform()) {
      const loading = await this.loadingController.create({});
      await loading.present();
      this.previewAnyFile.previewPath(apiSettings.baseUrl + $event.file.url, {
        name: $event.file.name,
        mimeType: $event.file.mime,
      }).subscribe(async () => {
        await loading.dismiss();
      }, async (e) => {
        await loading.dismiss();
        logger.error(e);
        await (await this.toastController.create({
          header: translations['components.document-list.preview-document-error'],
          message: this.translateService.instant('components.document-list.error-message', { error: e }),
          buttons: [translations['components.document-list.okay-action']],
          color: 'danger',
          duration: 5000,
          position: 'top',
        })).present();
      });
    } else {
      const modal = await this.modalController.create({
        component: MediaCarouselModalComponent,
        id: MediaCarouselModalComponent.MODAL_ID,
        canDismiss: true,
        backdropDismiss: true,
        cssClass: 'w-75-modal h-75-modal',
        presentingElement: this.routerOutlet.nativeEl,
        componentProps: {
          media: [$event.file],
        },
      });
      await modal.present();
    }
  }

  async requestDeletion(document: Document | DocumentFolder, isFolder = false) {
    const translations = await firstValueFrom(this.translateService.get([
      'components.document-list.cancel-action',
      'components.document-list.okay-action',
      'components.document-list.delete-action',
      'components.document-list.delete.template.header',
      'components.document-list.delete.folder.header-error',
      'components.document-list.delete.document.header',
      'components.document-list.delete.document.message',
      'components.document-list.delete.document.header-deleted',
      'components.document-list.delete.folder.header',
      'components.document-list.delete.folder.message',
      'components.document-list.delete.folder.header-deleted',
    ]));
    const alert = await this.alertController.create({
      header: isFolder ? translations['components.document-list.delete.folder.header'] : translations['components.document-list.delete.document.header'],
      message: this.translateService.instant(isFolder ? 'components.document-list.delete.folder.message' : 'components.document-list.delete.document.message', { title: isFolder ? (document as DocumentFolder).name : (document as Document).title }),
      buttons: [
        {
          text: translations['components.document-list.cancel-action'],
          role: 'cancel',
        },
        {
          text: translations['components.document-list.delete-action'],
          role: 'destructive',
          handler: async () => {
            const loading = await this.loadingController.create({});
            await loading.present();
            await this.deleteDocument(isFolder, document, translations);
            await loading.dismiss();
          },
        },
      ],
    });
    await alert.present();
  }

  async editDocument(document: Document) {
    await this.openDocumentModal(document);
  }

  async openDocumentModal(document?: Document | DocumentFolder, isFolder = false) {
    const translations = await firstValueFrom(this.translateService.get([
      'components.document-list.ok-action',
      'components.document-list.okay-action',
      'components.document-list.error-header',
      'components.document-list.open-document-modal-success.header.edited',
      'components.document-list.open-document-modal-success.header.edited-folder',
      'components.document-list.open-document-modal-success.header.uploaded',
    ]));

    const modal = await this.modalController.create({
      component: this.isTemplate() ? DocumentMaskComponent : UserAssignMaskComponent,
      id: this.isTemplate() ? DocumentMaskComponent.MODAL_ID : UserAssignMaskComponent.MODAL_ID,
      canDismiss: true,
      componentProps: this.isTemplate() ? {
        document,
        template: true,
      } : { assigned: document.participants.map(u => u.id) ?? [] },
      presentingElement: this.routerOutlet.nativeEl,
    });
    await modal.present();
    const { role, data } = await modal.onWillDismiss();
    if (this.isTemplate()) {
      if (role === 'success') {
        await this.clearCacheAndWait();
        await this.onCacheCleared(new Date());
      }
      return;
    }
    if (role !== UserAssignMaskComponent.SUCCESS_ROLE) {
      return;
    }

    const assigned = data.assigned;
    try {
      if (!isFolder) {
        await this.documentService.updateDocument({
          ...document as Document,
          participants: assigned,
        }, this.isTemplate());
      } else {
        await this.documentFolderService.updateFolder({
          ...document as DocumentFolder,
          participants: assigned,
        });
      }
      await this.clearCacheAndWait();
      await this.onCacheCleared(new Date());
      const toast = await this.toastController.create({
        header:
          isFolder ? translations['components.document-list.open-document-modal-success.header.edited-folder'] :
            document ?
              translations['components.document-list.open-document-modal-success.header.edited'] :
              translations['components.document-list.open-document-modal-success.header.uploaded'],
        message: document ?
          this.translateService.instant('components.document-list.open-document-modal-success.message.-updated', { data: isFolder ? (document as DocumentFolder).name : (document as Document).title }) :
          this.translateService.instant('components.document-list.open-document-modal-success.message.-created', { data: isFolder ? (document as DocumentFolder).name : (document as Document).title }),
        color: 'success',
        position: 'top',
        duration: 3000,
        buttons: [translations['components.document-list.okay-action']],
      });
      await toast.present();
    } catch (e) {
      logger.error(data);
      const toast = await this.toastController.create({
        header: translations['components.document-list.error-header'],
        message: this.translateService.instant('components.document-list.there-is-an-error-message', { error: e?.details?.message ?? e }),
        duration: 5000,
        buttons: [translations['components.document-list.ok-action']],
        color: 'danger',
        position: 'top',
      });
      await toast.present();
    }
  }

  @memoize()
  iconClass(file: Document) {
    if (!file.file) {
      return 'icon-file-text';
    }
    // file-docx
    // file-jpg
    // file-pdf
    // file-text
    // file-xlsx
    if (file.file.mime === 'application/pdf') {
      return 'icon-file-pdf';
    }

    if (file.file.mime.startsWith('image/')) {
      return 'icon-file-jpg';
    }

    if (file.file.ext.toLowerCase() === '.doc' || file.file.ext.toLowerCase() === '.docx') {
      return 'icon-file-docx';
    }

    if (file.file.ext.toLowerCase() === '.xls' || file.file.ext.toLowerCase() === '.xlsx') {
      return 'icon-file-xlsx';
    }

    return 'icon-file-text';
  }

  @memoize()
  fileName(document: Document) {
    if (!document.file) {
      return '-';
    }
    if (document.file.ext) {
      return document.file.ext.substring(1).toUpperCase();
    }
    return document.file?.name?.split('.')?.at(-1) ?? '-';
  }

  @memoize()
  convertFileSize(number: number) {
    const byte = number * 1000;
    if (byte < 1000) {
      return Math.floor(byte) + ' B';
    }
    if (byte < 1000 * 1000) {
      return Math.floor(byte / 1000) + ' KB';
    }

    if (byte < 1000 * 1000 * 1000) {
      return Math.floor(byte / 1000 / 1000) + ' MB';
    }

    if (byte < 1000 * 1000 * 1000 * 1000) {
      return Math.floor(byte / 1000 / 1000 / 1000) + ' GB';
    }
    return Math.floor(byte / 1000 / 1000 / 1000 / 1000) + ' TB';
  }

  checkDocument(id: number, $event) {
    this.checkedDocuments.update(v => {
        const index = v.findIndex(entry => entry === id);
        if ($event.detail.checked && index === -1) {
          const newSet = v.concat(id);
          newSet.sort();
          return newSet;
        } else if (!$event.detail.checked && index !== -1) {
          v.splice(index, 1);
          return [...v];
        }
      },
    );
  }

  checkFolder(id: number, $event) {
    this.checkedFolders.update(v => {
        const index = v.findIndex(entry => entry === id);
        if ($event.detail.checked && index === -1) {
          const newSet = v.concat(id);
          newSet.sort();
          return newSet;
        } else if (!$event.detail.checked && index !== -1) {
          v.splice(index, 1);
          return [...v];
        }
      },
    );
  }

  toggleCheckAll($event) {
    if ($event.detail.checked) {
      this.checkedFolders.set(this.folders().map(f => f.id));
      this.checkedDocuments.set(this.documents().map(f => f.id));
    } else {
      this.checkedDocuments.set([]);
      this.checkedFolders.set([]);
    }
  }

  downloadAll() {
    const docs = this.documents();
    const selectededDocs = this.checkedDocumentsSet();
    for (const d of docs) {
      if (selectededDocs.has(d.id)) {
        this.download(d);
      }
    }
  }

  async createFolder(folderNameInput: IonInput) {
    const folderName = (folderNameInput.value as string).trim();

    if (folderName == '') {
      return;
    }

    const property = this.selectedProperty();
    const unit = this.selectedUnit();
    const folder = this.selectedFolderSignal();

    try {
      const response = await this.documentFolderService.createFolder(folderName, folder?.id, property, unit);
      await this.clearCacheAndWait();
      await this.onCacheCleared(new Date());
      folderNameInput.value = '';
      this.isCreatingFolder.set(false);
      this.selectedFolderSignal.set(response);
    } catch (e) {
      console.error(e);
    }

  }

  async editFolder(folder: DocumentFolder) {
    await this.openDocumentModal(folder, true);
  }

  deleteFolder(folder: DocumentFolder) {
    return this.requestDeletion(folder, true);
  }

  async deleteAll() {
    const translations = await firstValueFrom(this.translateService.get([
      'components.document-list.cancel-action',
      'components.document-list.okay-action',
      'components.document-list.delete-action',
      'components.document-list.delete.all.header',
      'components.document-list.delete.all.header-deleted',
    ]));
    const checkedDocuments = this.checkedDocuments();
    const checkedFolders = this.checkedFolders();
    const documents = this.documents();
    const folders = this.folders();
    const alert = await this.alertController.create({
      header: translations['components.document-list.delete.all.header'],
      message: this.translateService.instant('components.document-list.delete.all.message', { amount: checkedDocuments.length + checkedFolders.length }),
      buttons: [
        {
          text: translations['components.document-list.cancel-action'],
          role: 'cancel',
        },
        {
          text: translations['components.document-list.delete-action'],
          role: 'destructive',
          handler: async () => {
            const loading = await this.loadingController.create({});
            await loading.present();

            await Promise.all(
              checkedDocuments
                .map(id => documents.find(d => d.id === id))
                .filter(Boolean)
                .map(d => this.deleteDocument(false, d, false))
                .concat(checkedFolders.map(id => folders.find(d => d.id === id))
                  .filter(Boolean)
                  .map(d => this.deleteDocument(true, d, false))),
            );

            await this.clearCacheAndWait();
            await this.onCacheCleared(new Date());

            await loading.dismiss();
          },
        },
      ],
    });
    await alert.present();
  }

  async shareAll() {
    const translations = await firstValueFrom(this.translateService.get([
      'components.document-list.ok-action',
      'components.document-list.okay-action',
      'components.document-list.error-header',
      'components.document-list.bulk-open-document-modal-success.header.edited',
    ]));

    const modal = await this.modalController.create({
      component: BulkUserAssignMaskComponent,
      id: BulkUserAssignMaskComponent.MODAL_ID,
      canDismiss: true,
      componentProps: {},
      cssClass: 'w-75-modal h-75-modal',
      presentingElement: this.routerOutlet.nativeEl,
    });
    await modal.present();
    const { role, data } = await modal.onWillDismiss();
    if (role !== BulkUserAssignMaskComponent.SUCCESS_ROLE) {
      return;
    }

    const assigned = data.assigned;
    const removed = data.removed;
    const loading = await this.loadingController.create({});
    await loading.present();
    try {
      const checkedDocuments = this.checkedDocuments();
      const checkedFolders = this.checkedFolders();
      const documents = this.documents();
      const folders = this.folders();

      await Promise.all(
        checkedDocuments
          .map(id => documents.find(d => d.id === id))
          .filter(Boolean)
          .map(d => this.documentService.updateDocument({
            ...d,
            participants: {
              connect: assigned,
              disconnect: removed,
            },
          }))
          .concat(checkedFolders.map(id => folders.find(d => d.id === id))
            .filter(Boolean)
            .map(d => this.documentFolderService.updateFolder({
              ...d,
              participants: {
                connect: assigned,
                disconnect: removed,
              },
            }))),
      );

      await this.clearCacheAndWait();
      await this.onCacheCleared(new Date());
      const toast = await this.toastController.create({
        message: translations['components.document-list.bulk-open-document-modal-success.header.edited'],
        color: 'success',
        duration: 3000,
        position: 'top',
        buttons: [translations['components.document-list.okay-action']],
      });
      await toast.present();
    } catch (e) {
      logger.error(data);
      const toast = await this.toastController.create({
        header: translations['components.document-list.error-header'],
        message: this.translateService.instant('components.document-list.there-is-an-error-message', { error: e?.details?.message ?? e }),
        duration: 5000,
        buttons: [translations['components.document-list.ok-action']],
        color: 'danger',
        position: 'top',
      });
      await toast.present();
    }
    await loading.dismiss();
  }

  nextDocumentPage() {
    this.currentDocumentPage.update(p => p + 1);
  }

  previousDocumentPage() {
    this.currentDocumentPage.update(p => p === 1 ? 1 : p - 1);
  }

  nextFolderPage() {
    this.currentFolderPage.update(p => p + 1);
  }

  previousFolderPage() {
    this.currentFolderPage.update(p => p + 1);
  }

  async updateFolder(folder: DocumentFolder) {
    return this.updateFile(folder, true);
  }

  updateDocument(file: Document) {
    return this.updateFile(file, false);
  }

  private async deleteDocument(isFolder: boolean, document: Document | DocumentFolder, clearCache = true) {
    const translations = await firstValueFrom(this.translateService.get([
      'components.document-list.cancel-action',
      'components.document-list.okay-action',
      'components.document-list.delete-action',
      'components.document-list.delete.template.header',
      'components.document-list.delete.folder.header',
      'components.document-list.delete.document.header',
      'components.document-list.delete.document.header-deleted',
      'components.document-list.delete.document.message.deleted',
      'components.document-list.delete.folder.header',
      'components.document-list.delete.folder.message.deleted',
      'components.document-list.delete.folder.header-deleted',
    ]));
    try {
      if (!isFolder) {
        if ((document as Document).file?.id) {
          await this.imageService.deleteFile((document as Document).file?.id);
        }
        await this.documentService.deleteDocument(document as Document, this.isTemplate());
      } else {
        await this.documentFolderService.deleteFolder(document as DocumentFolder);
      }

      await (await this.toastController.create({
        header: isFolder ? translations['components.document-list.delete.folder.header-deleted'] : translations['components.document-list.delete.document.header-deleted'],
        message: this.translateService.instant(isFolder ? 'components.document-list.delete.folder.message.deleted' : 'components.document-list.delete.document.message.deleted', { title: isFolder ? (document as DocumentFolder).name : (document as Document).title }),
        buttons: [translations['components.document-list.okay-action']],
        color: 'success',
        duration: 5000,
        position: 'top',
      })).present();
    } catch (e) {
      logger.error(e);
      await (await this.toastController.create({
        header: isFolder ? translations['components.document-list.delete.folder.header'] : translations['components.document-list.delete.template.header'],
        message: this.translateService.instant('components.document-list.error-message', { error: e }),
        buttons: [translations['components.document-list.okay-action']],
        color: 'danger',
        duration: 5000,
        position: 'top',
      })).present();
    }

    if (clearCache) {
      await this.clearCacheAndWait();
      await this.onCacheCleared(new Date());
    }
  }

  private async loadDocuments(
    showLoading = false,
    folder: number | undefined = undefined,
    property: number | undefined = undefined,
    unit: number | undefined = undefined,
    page = 1,
    pageSize = 25,
  ): Promise<void> {
    // this.isLoading = true;

    let loading;
    // if (showLoading) {
    //   loading = await this.loadingController.create({
    //     showBackdrop: false,
    //   });
    //   await loading.present();
    // }

    let filterMode;
    if (this.hasPermissions()) {
      filterMode = 'company';
    } else {
      filterMode = 'tenant';
    }

    try {
      if (this.isTemplate()) {
        const { data, meta } = await this.documentService.getDocumentTemplates(
          this.searchTerm(),
          this.isFuzzySearch(),
          filterMode,
          page,
          pageSize,
          this.sortField,
          this.sortMode,
        );
        this.documents.set(data);
        this.documentsPagination.set(meta);
      } else {
        const { data, meta } = await this.documentService.getDocuments(
          this.searchTerm(),
          this.isFuzzySearch(),
          filterMode,
          folder,
          property,
          unit,
          page,
          pageSize,
          this.sortField,
          this.sortMode,
        );
        this.documents.set(data);
        this.documentsPagination.set(meta);
      }

    } catch (e) {
      logger.error(e);
    } finally {
      // if (showLoading) {
      //   await loading.dismiss();
      // }
      // this.isLoading = false;
    }
  }

  private async loadFolders(parentId?: number, propertyId?: number, unitId?: number, page = 1, pageSize = 25) {
    if (this.isTemplate()) {
      return;
    }
    const response = await this.documentFolderService.getFolders(parentId, propertyId, unitId, page, pageSize);
    this.folders.set(response.data);
    this.foldersPagination.set(response.meta);
  }

  private async updateFile(file: Document | DocumentFolder, isFolder = false) {
    const newName = this.editingName();
    const translations = await firstValueFrom(this.translateService.get([
      'components.document-list.ok-action',
      'components.document-list.okay-action',
      'components.document-list.error-header',
      'components.document-list.open-document-modal-success.header.edited',
      'components.document-list.open-document-modal-success.header.edited-folder',
    ]));
    try {
      if (isFolder) {
        await this.documentFolderService.updateFolder({
          ...file as DocumentFolder,
          name: newName,
        });
      } else {
        await this.documentService.updateDocument({
          ...file as Document,
          title: newName,
        });
      }
      await this.clearCacheAndWait();
      await this.onCacheCleared(new Date());
      const toast = await this.toastController.create({
        message: isFolder
          ? translations['components.document-list.open-document-modal-success.header.edited-folder']
          : translations['components.document-list.open-document-modal-success.header.edited'],
        color: 'success',
        duration: 3000,
        position: 'top',
        buttons: [translations['components.document-list.okay-action']],
      });
      await toast.present();
    } catch (e) {
      logger.error(e);
      const toast = await this.toastController.create({
        header: translations['components.document-list.error-header'],
        message: this.translateService.instant('components.document-list.there-is-an-error-message', { error: e?.details?.message ?? e }),
        duration: 5000,
        buttons: [translations['components.document-list.ok-action']],
        color: 'danger',
        position: 'top',
      });
      await toast.present();
    }

    this.editingFolder.set(undefined);
    this.editingFile.set(undefined);
    this.editingName.set('');
  }
}

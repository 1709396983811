import { inject, Injectable, signal } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Device } from '@capacitor/device';
import { CacheService } from '@core/api/cache/cache.service';
import { ApiService } from '@core/api/api.service';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  protected entity = 'i18n/locales';
  private translateService = inject(TranslateService);
  private cache = inject(CacheService);
  private apiService = inject(ApiService);
  private language?: string | null;
  private availableLanguages: Map<string, string> = new Map();
  isLanguageChanges = signal<boolean>(false);

  get lang() {
    if (!this.language) {
      this.staticLoadLanguage();
    }
    return this.language ?? 'de';
  }

  set lang(value: string | null | undefined) {
    this.language = value;
    this.translateService.use(this.lang!);
    this.cache.clearCache().then();
    localStorage.setItem('LANGUAGE', JSON.stringify(value));
    this.isLanguageChanges.set(true);
  }

  get available() {
    return this.availableLanguages;
  }

  async loadTranslations() {
    if (!this.availableLanguages.size) {
      await this.loadAvailableLanguages();
    }
    if (this.language == null) {
      await this.getLanguage();
    }
    this.translateService.setDefaultLang('de');
    this.translateService.use(this.language!);
  }

  staticLoadLanguage() {
    try {
      this.language = JSON.parse(localStorage.getItem('LANGUAGE') ?? JSON.stringify(null));
      console.debug('LOADED LANGUAGE:', this.language);
    } catch (e) {
      console.error(e);
    }
  }

  async getLanguage() {
    this.staticLoadLanguage();
    try {
      if (!this.language) {
        this.language = (await Device.getLanguageCode()).value;
        localStorage.setItem('LANGUAGE', JSON.stringify(this.language));
        console.debug('DEVICE FALLBACK LANGUAGE:', this.language);
      }
    } catch (e) {
      console.error(e);
      console.debug('DEFAULTING to DE');
      this.language = 'de';
      localStorage.setItem('LANGUAGE', JSON.stringify(this.language));
    }
    return this.language!;
  }

  private async loadAvailableLanguages() {
    this.availableLanguages.clear();
    try {
      const result: Locale[] = await this.apiService.getWithCaching(`api/i18n/locales`);
      result.forEach(lang => {
        this.availableLanguages.set(lang.code, lang.name);
      });
    } catch (e) {
      console.error(e);
      this.availableLanguages.set('de', 'Deutsch (German)');
      this.translateService.use('de');
    }
  }
}

interface Locale {
  code: string;
  createdAt: string;
  id: number;
  isDefault: boolean;
  name: string;
  updatedAt: string;
}

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Pagination } from '@core/models/datatypes/pagination.interface';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
})
export class PaginationComponent {

  @Input() pagination?: Pagination;
  @Input() asCard = true;
  @Input() label = 'Total';

  @Output() toStart = new EventEmitter();
  @Output() toEnd = new EventEmitter();
  @Output() previous = new EventEmitter();
  @Output() next = new EventEmitter();
  @Output() sizeChange = new EventEmitter<number>();

  constructor(
    private translateService :TranslateService,
    ) {
    if (this.label === 'Total') {
      this.label = this.getTranslated('components.pagination.total-label');
    }
  }

  emitSizeChange($event: any) {
    this.sizeChange.emit($event?.detail?.value ?? 25);
  }

  getTranslated(key: string){
    return this.translateService.instant(key);
  }
}

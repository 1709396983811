import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthenticationGuard } from '@app/auth/authentication.guard';
import { MenuComponent } from '@shared/components/menu/menu.component';
import { RedirectContractorsToTicketsGuard } from '@core/guards/redirect-contractors-to-tickets.guard';
import { RedirectComponent } from '@app/redirect.component';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    component: RedirectComponent,
    canActivate: [RedirectContractorsToTicketsGuard]
  },
  {
    component: MenuComponent,
    path: 'app',
    canActivate: [AuthenticationGuard],
    children: [
      {
        path: 'dashboard',
        loadChildren: () => import('./pages/dashboard/dashboard.module').then( m => m.DashboardPageModule)
      },
      {
        path: 'blackboard',
        loadChildren: () => import('./pages/constituent/properties/property-details/blackboard/blackboard.module')
          .then(m => m.BlackboardPageModule),
      },
      {
        path: 'profile',
        loadChildren: () => import('./pages/user/profile/profile-page.module').then(m => m.ProfilePageModule),
      },
      {
        path: 'properties',
        loadChildren: () => import('./pages/constituent/properties/properties.module').then(m => m.PropertiesPageModule),
      },
      {
        path: 'chat-rooms',
        loadChildren: () => import('./pages/constituent/chat-rooms/chat-rooms.module').then(m => m.ChatRoomsPageModule),
      },
      {
        path: 'meter-readings',
        loadChildren: () => import('./pages/constituent/meters/meter-readings/meter-readings.module').then(m => m.MeterReadingsPageModule),
      },
      {
        path: 'documents',
        loadChildren: () => import('./pages/constituent/documents/documents.module').then(m => m.DocumentsPageModule),
      },
      {
        path: 'document-templates',
        loadChildren: () => import('./pages/constituent/document-templates/document-templates.module')
          .then(m => m.DocumentTemplatesPageModule),
      },
      {
        path: 'users',
        loadChildren: () => import('./pages/user/list/user-list.module').then(m => m.UserListPageModule),
      },
      {
        path: 'problems',
        loadChildren: () => import('./pages/manager/problems/problems.module').then(m => m.ProblemsPageModule),
      },
      {
        path: 'tickets',
        loadChildren: () => import('./pages/manager/tickets/tickets.module').then(m => m.TicketsPageModule),
      },
      {
        path: 'contacts',
        loadChildren: () => import('./pages/manager/contacts/contacts.module').then(m => m.ContactsPageModule),
      },
      {
        path: 'contractors',
        loadChildren: () => import('./pages/manager/contractors/contractors.module')
          .then(m => m.ContractorsPageModule),
      },
      {
        path: 'support',
        loadChildren: () => import('./pages/support/support.module').then( m => m.SupportPageModule)
      },
      {
        path: 'finance',
        loadChildren: () => import('./pages/manager/finance/finance.module').then(m => m.FinancePageModule)
      },
      {
        path: 'finapi',
        loadChildren: () => import('./pages/finapi/finapi.module').then( m => m.FinapiPageModule)
      },
    ],
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule),
  },
  {
    path: 'invitation',
    loadChildren: () => import('./pages/invitation/invitation.module').then(m => m.InvitationPageModule),
  },
  {
    path: 'not-found',
    loadChildren: () => import('./pages/error/not-found/not-found.module').then(m => m.NotFoundPageModule),
  },
  {
    path: 'reset',
    loadChildren: () => import('./pages/reset/reset.module').then(m => m.ResetPageModule),
  },
  /** CATCH ALL ROUTE: NOTHING BEHIND THESE LINES **/
  {
    path: '**',
    pathMatch: 'full',
    redirectTo: '/not-found',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}

import { Component, forwardRef, HostBinding, Input, OnInit, signal } from '@angular/core';
import { ApportionmentFormula, CostType } from '@core/models/interfaces/cost-type.interface';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CostTypeService } from '@core/api/cost-type/cost-type.service';
import { ModalController } from '@ionic/angular';
import { ChooseFormulaModalComponent } from '@shared/components/cost-type-field/choose-formula-modal/choose-formula-modal.component';
import { fuzzy } from '@core/utils/fuzzy';
import { AddCostTypeModalComponent } from '@shared/components/cost-type-field/add-cost-type-modal/add-cost-type-modal.component';
import { CacheService } from '@core/api/cache/cache.service';

@Component({
  selector: 'app-cost-type-field',
  templateUrl: './cost-type-field.component.html',
  styleUrls: ['./cost-type-field.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CostTypeFieldComponent),
      multi: true,
    },
  ],
})
export class CostTypeFieldComponent implements OnInit, ControlValueAccessor {
  @HostBinding('attr.id')
  externalId = '';

  @Input() onlyNonApporionable: boolean = false;

  @Input('value') pValue: { costType?: CostType, customApportionmentFormula?: ApportionmentFormula } | null = {
    costType: undefined,
    customApportionmentFormula: undefined,
  };

  apportionableCategories = signal<CostType[]>([]);
  apportionableCategoriesWithoutFormula = signal<CostType[]>([]);
  nonApportionableCategories = signal<CostType[]>([]);
  searchTerm: string;
  #apportionableCategories: CostType[] = [];
  #apportionableCategoriesWithoutFormula: CostType[] = [];
  #nonApportionableCategories: CostType[] = [];
  #id = '';
  #propertyId?: number = undefined;

  constructor(
    private costTypeService: CostTypeService,
    private modalController: ModalController,
    private cacheService: CacheService,
  ) { }

  get id() {
    return this.externalId ?? this.#id;
  }

  @Input()
  set id(value: string) {
    this.#id = value;
    this.externalId = null;
  }

  get propertyId() {
    return this.#propertyId;
  }

  @Input() set propertyId(value: number | undefined) {
    this.#propertyId = value;
    this.loadCostTypes();
  }

  get value() {
    return this.pValue;
  }

  set value(val) {
    console.debug({ previous: this.pValue, next: val });
    this.pValue = val;
    this.clearSearch();
    this.onChange(val);
    this.onTouched();
  }

  onChange: any = () => {};

  onTouched: any = () => {};

  async ngOnInit() {
    await this.loadCostTypes();
  }

  registerOnChange(fn) {
    this.onChange = fn;
  }

  writeValue(value) {
    this.value = value;
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  mapFormula(apportionmentFormula: ApportionmentFormula | undefined) {
    switch (apportionmentFormula) {
      case 'direct':
        return 'components.cost-type-field.apportionment-formula.direct';
      case 'living_space':
        return 'components.cost-type-field.apportionment-formula.living-space';
      case 'people':
        return 'components.cost-type-field.apportionment-formula.people';
      case 'units':
        return 'components.cost-type-field.apportionment-formula.residential-units';
      case 'water':
        return 'components.cost-type-field.apportionment-formula.water-consumption';
      default:
        return '';
    }

  }

  async chooseApportionmentFormula() {
    const modal = await this.modalController.create({
      component: ChooseFormulaModalComponent,
      componentProps: {
        data: {
          property: this.propertyId,
          costType: this.value.costType,
        },
      },
    });
    await modal.present();

    const { data, role } = await modal.onWillDismiss();
    if (role === 'confirm') {
      this.value = { ...this.value, customApportionmentFormula: data };
    }
  }

  async openCreateCostType() {
    const modal = await this.modalController.create({
      component: AddCostTypeModalComponent,
      cssClass: 'h-75-modal',
      componentProps: {
        data: {
          propertyId: this.propertyId,
        },
      },
    });
    await modal.present();

    const { data, role } = await modal.onWillDismiss();
    await this.cacheService.clearCache();
    await this.loadCostTypes();
    if (role === 'confirm') {
      this.clearSearch();
      this.setCostType(data[0]);
    }
  }

  setCostType(category: CostType) {
    this.value = { ...this.value, costType: category };
  }

  onSearch($event: any) {
    const lowerCaseSearchTerm = $event.detail.value.toLowerCase().trim();
    this.apportionableCategories.set(this.#apportionableCategories.filter(c => c.name.toLowerCase().includes(lowerCaseSearchTerm) || fuzzy(lowerCaseSearchTerm, c.name, 0.5)));
    this.apportionableCategoriesWithoutFormula.set(this.#apportionableCategoriesWithoutFormula.filter(c => c.name.toLowerCase().includes(lowerCaseSearchTerm) || fuzzy(lowerCaseSearchTerm, c.name, 0.5)));
    this.nonApportionableCategories.set(this.#nonApportionableCategories.filter(c => c.name.toLowerCase().includes(lowerCaseSearchTerm) || fuzzy(lowerCaseSearchTerm, c.name, 0.5)));
  }

  clearSearch() {
    this.searchTerm = '';
    this.apportionableCategories.set(this.#apportionableCategories);
    this.apportionableCategoriesWithoutFormula.set(this.#apportionableCategoriesWithoutFormula);
    this.nonApportionableCategories.set(this.#nonApportionableCategories);
  }

  private async loadCostTypes() {
    let page = 1;
    const pageSize = 25;
    let hasMore = false;
    let costTypes: CostType[] = [];
    do {
      const types = await this.costTypeService.getCostTypes(this.propertyId, page, pageSize);
      if (types.meta.pageCount > page) {
        page++;
        hasMore = true;
      } else {
        hasMore = false;
      }

      costTypes = costTypes.concat(types.data);
    } while (hasMore);
    this.#apportionableCategories = costTypes.filter(c => c.nonApportionable === false && c.apportionmentFormula != undefined);
    this.#apportionableCategoriesWithoutFormula = costTypes.filter(c => c.nonApportionable === false && c.apportionmentFormula == undefined);
    this.#nonApportionableCategories = costTypes.filter(c => c.nonApportionable === true);

    this.apportionableCategories.set(this.#apportionableCategories);
    this.apportionableCategoriesWithoutFormula.set(this.#apportionableCategoriesWithoutFormula);
    this.nonApportionableCategories.set(this.#nonApportionableCategories);
  }
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { User } from '@core/models/interfaces/user.interface';
import { Property } from '@core/models/interfaces/property.interface';
import { Unit } from '@core/models/interfaces/unit.interface';
import { UserService } from '@core/api/user/user.service';
import { LoadingController, ModalController, ToastController } from '@ionic/angular';
import { Logger } from '@core/logger/logger.service';
import { ProblemService } from '@core/api/problem-service/problem.service';
import { Problem } from '@app/@core/models/interfaces/problem.interface';
import { CacheService } from '@app/@core/api/cache/cache.service';
import { UsesCacheComponent } from '@core/api/cache/uses-cache';
import { TranslateService } from '@ngx-translate/core';
import { firstValueFrom } from 'rxjs';

const logger = new Logger('a.@s.c.p.ProblemCreatorMaskComponent');

@Component({
  selector: 'app-problem-creator-mask',
  templateUrl: './problem-creator-mask.component.html',
  styleUrls: ['./problem-creator-mask.component.scss'],
})
export class ProblemCreatorMaskComponent extends UsesCacheComponent implements OnInit {
  public static MODAL_ID = 'problem-creator-mask-component';
  @Input() property?: Property;
  @Input() unit?: Unit;
  @Input() problem?: Problem;
  @Input() edit = false;
  @Input() isModal = false;
  @Input() isUnit = false;
  @Output() closeEditor = new EventEmitter<void>();
  private form?: UntypedFormGroup;
  private user: User;

  constructor(
    private readonly formBuilder: UntypedFormBuilder,
    private readonly userService: UserService,
    private readonly toastController: ToastController,
    private readonly problemService: ProblemService,
    private readonly modalController: ModalController,
    private readonly loadingController: LoadingController,
    protected readonly cacheService: CacheService,
    private translateService :TranslateService,
  ) {
    super(cacheService);
  }

  get minDescriptionLength() {
    return 20;
  }

  get maxDescriptionLength() {
    return 250;
  }

  get editorForm() {
    return this.form;
  }

  async onCacheCleared(lastRefresh: Date): Promise<void> {
    this.user = await this.userService.getMe();
  }

  async ngOnInit() {
    this.user = await this.userService.getMe();
    this.form = this.createForm();
    this.isInitialized = true;
  }

  async createProblem() {
    if (this.form.disabled || this.form.invalid || (this.edit && !this.problem)) {
      return;
    }

    const translations = await firstValueFrom(this.translateService.get([
      'components.problem-creator-mask.okay-action',
      'components.problem-creator-mask.fault-forwarded-error-header',
      'components.problem-creator-mask.fault-forwarded-header',
      'components.problem-creator-mask.fault-forwarded-message'
    ]));
    this.form.disable();
    const loading = await this.loadingController.create({});
    await loading.present();
    const { description, filesUploaded } = this.form.value;

    try {
      let id;
      if (!this.edit) {
        let newProblem;
        if (this.isUnit) {
          const unit = this.unit;
          if (!unit.property) {
            unit.property = this.property;
          }
          newProblem = await this.problemService.createProblemForUnit(unit, this.user.id, description);
        } else {
          newProblem = await this.problemService.createProblemForProperty(this.property, this.user.id, description);

        }
        const { id: newProblemId } = newProblem.data;
        id = newProblemId;
      } else {
        id = this.problem.id;
        await this.problemService.updateProblem(id, description);
      }
      if (filesUploaded) {
        await this.problemService.uploadFiles(
          id,
          filesUploaded,
          this.edit ? this.problem.attachments?.map((a) => a.id) : undefined,
        );
      }

      this.form.reset();
      await (await this.toastController.create({
        header: translations['components.problem-creator-mask.fault-forwarded-header'],
        message: translations['components.problem-creator-mask.fault-forwarded-message'],
        buttons: [translations['components.problem-creator-mask.okay-action']],
        color: 'success',
        duration: 5000,
        position: 'top',
      })).present();
      await this.cacheService.clearCache();
      await this.dismiss(true);
    } catch (e) {
      logger.error(e);
      await (await this.toastController.create({
        header: translations['components.problem-creator-mask.fault-forwarded-error-header'],
        message: this.translateService.instant('components.problem-creator-mask.error-message', {error: e}),
        buttons: [translations['components.problem-creator-mask.okay-action']],
        color: 'danger',
        duration: 5000,
        position: 'top',
      })).present();
    } finally {
      this.form?.enable();
      await loading.dismiss();
    }
  }

  changeFiles($event: any) {
    this.form.patchValue({ filesUploaded: $event.target.files });
  }

  async dismiss(success = false) {
    if (this.isModal) {
      await this.modalController.dismiss({}, success ? 'success' : 'dismiss', ProblemCreatorMaskComponent.MODAL_ID);
    } else {
      this.closeEditor.emit();
    }
  }

  private createForm() {
    return this.formBuilder.group({
      description: [this.edit ? this.problem.body : '', Validators.compose([
        Validators.required,
        Validators.minLength(this.minDescriptionLength),
        Validators.maxLength(this.maxDescriptionLength),
      ])],
      files: [null],
      filesUploaded: [null],
    });
  }

  getTranslated(key: string){
    return this.translateService.get(key);
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { UserService } from '@app/@core/api/user/user.service';
import { User } from '@core/models/interfaces/user.interface';
import { UsesCacheComponent } from '@core/api/cache/uses-cache';
import { CacheService } from '@core/api/cache/cache.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-contact-card',
  templateUrl: './contact-card.component.html',
  styleUrls: ['./contact-card.component.scss'],
})
export class ContactCardComponent extends UsesCacheComponent implements OnInit {

  @Input() tenant: User;
  @Input() header = '';
  @Input() role = '';
  @Input() showHeader = true;

  private currentUser: User;

  constructor(
    private readonly userService: UserService,
    protected readonly cacheService: CacheService,
    private translateService :TranslateService,
  ) {
    super(cacheService);
  }

  async ngOnInit() {
    this.currentUser = await this.userService.getMe();
    this.isInitialized = true;
    if (this.header === '')
      this.header = this.translateService.instant('components.contact-card.contact');
    if (this.role === '')
      this.role = this.translateService.instant('components.contact-card.renter');
  }

  hasPermissions() {
    return ['admin', 'manager'].includes(this.currentUser?.role?.type);
  }

  async onCacheCleared(lastRefresh: Date): Promise<void> {
    this.currentUser = await this.userService.getMe();
  }
}

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { UserService } from '@core/api/user/user.service';

@Injectable({
  providedIn: 'root',
})
export class RedirectContractorsToTicketsGuard  {

  constructor(
    private readonly userService: UserService,
    private router: Router,
  ) {
  }

  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<boolean | UrlTree> {
    try {
      //@TODO IRES-456 change '/app/properties'  to '/app/dashboard' as a home page
      const me = await this.userService.getMe();
      if (!me) {
        return this.router.parseUrl('/app/properties');
      }
      if ((me.role?.type ?? 'public') === 'contractor') {
        return this.router.parseUrl('/app/tickets');
      }

      return this.router.parseUrl('/app/properties');
    } catch (e) {
      return this.router.parseUrl('/app/properties');
    }
  }

}
